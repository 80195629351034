import React, { CSSProperties, FunctionComponent } from 'react';
import Router from 'next/router';

import { trackPage } from '../utils/Analytics';

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  trackPage(url);
});

type PageProps = {
  style?: CSSProperties;
  children: JSX.Element | JSX.Element[];
};

const Page: FunctionComponent<PageProps> = ({ style, children }) => {
  return (
    <div style={style} suppressHydrationWarning>
      {children}
    </div>
  );
};

export default Page;
