import { AppProps } from 'next/app';
import Head from 'next/head';
import Page from '../components/Page';
import '../styles/global.scss';
import CookieHubLoader from '../components/cookie-consent/CookieHubLoader';

interface AppErrorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any;
}

const App = ({ Component, pageProps, err }: AppProps & AppErrorProps) => {
  return (
    <Page>
      <Head>
        <link rel="preload" href="/fonts/CircularPro-Book.otf" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/CircularPro-Bold.otf" as="font" crossOrigin="" />
        <meta name="facebook-domain-verification" content="h82aa7hydbn88nwlii4zheuah9nnas" />
      </Head>
      <Component {...pageProps} err={err} />
      <CookieHubLoader />
    </Page>
  );
};

export default App;
